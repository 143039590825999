<template>
  <div class="selling-point-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.sellingPoints"
      :childOne="content.sellingPoints"
      routeOne="/sellingpoints/ourstudio"
    />
    <div class="grid grid-cols-7 gap-x-10">
      <div class="col-span-7 lg:col-span-2">
        <div class="selling-points-tabs bg-blueLight lora-bold">
          <router-link to="/sellingpoints/ourstudio">
            <div class="tab flex justify-between items-center">
              {{ content.ourStudio }}
              <i class="fas fa-chevron-right"></i>
            </div>
          </router-link>
          <router-link to="/sellingpoints/onlinesellingpoints">
            <div class="tab flex justify-between items-center">
              {{ content.onlineSellingPoints }}
              <i class="fas fa-chevron-right"></i>
            </div>
          </router-link>
          <router-link to="/sellingpoints/sellingsites">
            <div class="tab flex justify-between items-center">
              {{ content.sellingSites }}
              <i class="fas fa-chevron-right"></i>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-span-7 lg:col-span-5 mt-10 lg:mt-0">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  mounted() {
    this.$store.dispatch("shops/studio");
    this.$store.dispatch("shops/onlineShops");
    this.$store.dispatch("shops/sellingPoints");
  },
};
</script>